import { Button } from "@shopify/polaris";
import { GroupedButton } from "../../../../../component/GroupedButton/GroupedButton";
import {
  NFCE_STEPS,
  PAYMENT_STEPS,
  usePaymentFlowStore,
} from "../../../../../store/usePaymentFlowStore";
import { Title } from "../../Title/Title";
import { ActionsContainer, Container } from "./ChooseStep.styles";

export function ChooseStep() {
  const { setPaymentStep, setNfceStep } = usePaymentFlowStore();

  const goToInformationTab = () => setNfceStep(NFCE_STEPS.INFORMATION.value);

  const goToUniquePaymentStep = () =>
    setPaymentStep(PAYMENT_STEPS.UNIQUE_PAYMENT.value);

  const goToMultiplePaymentsStep = () =>
    setPaymentStep(PAYMENT_STEPS.MULTIPLE_PAYMENTS.value);

  const paymentOptions = [
    {
      id: "1",
      text: "Único pagamento",
      onClick: goToUniquePaymentStep,
    },
    {
      id: "2",
      text: "Múltiplos pagamentos",
      onClick: goToMultiplePaymentsStep,
    },
  ];

  return (
    <Container>
      <Title>Pagamento</Title>

      <GroupedButton
        title="Selecionar forma de pagamento"
        options={paymentOptions}
      />

      <ActionsContainer>
        <Button onClick={goToInformationTab}>Voltar</Button>
      </ActionsContainer>
    </Container>
  );
}
