import { Select } from "@shopify/polaris";
import { useFlags } from "launchdarkly-react-client-sdk";

import { Container } from "./PaymentMethodSelect.styles";

export function PaymentMethodSelect({ value, onChange }) {
  const {
    disableStoreCredit: disableStoreCredit,
    disableMethodCash: disableMethodCash,
    disableMethodPix: disableMethodPix,
  } = useFlags();

  const options = [
    { label: "Crédito", value: "CREDIT" },
    { label: "Débito", value: "DEBIT" },
    { label: "Link de Pagamento", value: "LINK" },
  ];

  if (!disableStoreCredit) {
    options.push({ label: "Troca - Créditos", value: "STORE-CREDIT" });
  }

  if (!disableMethodCash) {
    options.push({ label: "Dinheiro", value: "CASH" });
  }

  if (!disableMethodPix) {
    options.push({ label: "PIX", value: "PIX" });
  }

  return (
    <Container>
      <Select
        placeholder="Selecione o método de pagamento..."
        options={options}
        value={value ?? undefined}
        onChange={onChange}
      />
    </Container>
  );
}
