import { useUniquePaymentStore } from "../../../../../../../store/useUniquePaymentStore";
import { ChargedSeparatelySwitcher as ChargedSeparatelySwitcherField } from "../../../components/ChargedSeparatelySwitcher/ChargedSeparatelySwitcher";

export function ChargedSeparatelySwitcher() {
  const { paymentMethod, chargedSeparately, setChargedSeparately } =
    useUniquePaymentStore();

  const shouldRenderThisField = () => {
    if (
      !paymentMethod ||
      paymentMethod === "CASH" ||
      paymentMethod === "LINK" ||
      paymentMethod === "STORE-CREDIT"
    ) {
      return false;
    }

    return true;
  };

  if (!shouldRenderThisField()) {
    return null;
  }

  const handleChange = (checked) => setChargedSeparately(checked);

  return (
    <ChargedSeparatelySwitcherField
      checked={chargedSeparately}
      onChange={handleChange}
    />
  );
}
