import { create } from "zustand";

import { useUniquePaymentStore } from "./useUniquePaymentStore";

export const UNIQUE_PAYMENT_STEPS = {
  PAYMENT_FORM: {
    value: 1,
  },
  CARD: {
    value: 2,
  },
  PIX: {
    value: 3,
  },
};

export const MULTIPLE_PAYMENT_STEPS = {
  PAYMENT_FORM_LIST: {
    value: 1,
  },
  CARD: {
    value: 2,
  },
  PIX: {
    value: 3,
  },
};

export const PAYMENT_STEPS = {
  CHOOSE_STEP: {
    value: 1,
  },
  UNIQUE_PAYMENT: {
    value: 2,
    STEPS: UNIQUE_PAYMENT_STEPS,
  },
  MULTIPLE_PAYMENTS: {
    value: 3,
    STEPS: MULTIPLE_PAYMENT_STEPS,
  },
};

export const NFCE_STEPS = {
  INFORMATION: {
    value: 1,
  },
  PAYMENT: {
    value: 2,
    STEPS: PAYMENT_STEPS,
  },
  NFCE: {
    value: 3,
  },
};

export const usePaymentFlowStore = create((set) => ({
  installmentOptions: [],
  shouldAvoidPaymentTypeStep: false,

  setShouldAvoidPaymentTypeStep(shouldAvoidPaymentTypeStep) {
    set({ shouldAvoidPaymentTypeStep });
  },

  setInstallmentOptions(installmentOptions) {
    set({
      installmentOptions,
    });

    useUniquePaymentStore.setState({
      paymentInstallments: installmentOptions?.[0].value ?? null,
    });
  },

  nfceStep: NFCE_STEPS.INFORMATION.value,
  paymentStep: PAYMENT_STEPS.CHOOSE_STEP.value,
  uniquePaymentStep: UNIQUE_PAYMENT_STEPS.PAYMENT_FORM.value,
  multiplePaymentsStep: MULTIPLE_PAYMENT_STEPS.PAYMENT_FORM_LIST.value,

  setNfceStep(nfceStep) {
    set({ nfceStep });
  },

  setPaymentStep(paymentStep) {
    set({ paymentStep });
  },

  setUniquePaymentStep(uniquePaymentStep) {
    set({ uniquePaymentStep });
  },

  setMultiplePaymentsStep(multiplePaymentsStep) {
    set({ multiplePaymentsStep });
  },
}));
