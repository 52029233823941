import { Button } from "@shopify/polaris";

export function LinkSubmit({ disabled, onClick }) {
  const getIsDisabled = () => {
    return disabled;
  };

  const isDisabled = getIsDisabled();

  return (
    <Button primary disabled={isDisabled} onClick={onClick}>
      Continuar
    </Button>
  );
}
