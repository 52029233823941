import { useUniquePaymentStore } from "../../../../../../../store/useUniquePaymentStore";
import { AutField as AutFieldInput } from "../../../components/AutFied/AutFied";

export function AutField() {
  const {
    paymentMethod,
    chargedSeparately,
    aut,
    setAut,
    automaticConciliation,
  } = useUniquePaymentStore();

  if (
    paymentMethod === "" ||
    (paymentMethod === "PIX" && !chargedSeparately) ||
    !chargedSeparately ||
    (chargedSeparately && automaticConciliation)
  ) {
    return null;
  }

  const handleChange = (value) => setAut(value);

  return <AutFieldInput value={aut} onChange={handleChange} />;
}
