import { useNavigate } from "@shopify/app-bridge-react";
import { Icon } from "@shopify/polaris";
import { ChevronRightMinor } from "@shopify/polaris-icons";
import React from "react";

import useHideLoadingIndicator from "../../hook/useHideLoadingIndicator.js";

import { OneButtonContainer, OneButtonStandard } from "./styles.js";

const Button = ({ text, navigateTo }) => {
  const navigate = useNavigate();
  const handleNavigation = () => navigate(navigateTo);

  return (
    <OneButtonStandard onClick={handleNavigation}>
      {text}
      <div>
        <Icon source={ChevronRightMinor} />
      </div>
    </OneButtonStandard>
  );
};

export default function OneButtonPage() {
  useHideLoadingIndicator();

  return (
    <OneButtonContainer>
      <Button text="Pagamento/NF" navigateTo="/nfce-page" />
      <Button text="Devolução" navigateTo="/return" />
      <Button text="Cancelar" navigateTo="/cancel-sale" />
    </OneButtonContainer>
  );
}
