import { useCurrentMultiplePayment } from "../../../../../../../store/useMultiplePaymentsStore";
import { AutField as AutFieldInput } from "../../../components/AutFied/AutFied";

export function AutField() {
  const { currentPayment, setCurrentPayment } = useCurrentMultiplePayment();

  const shouldRenderThisField = () => {
    if (
      currentPayment.chargedSeparately &&
      currentPayment.automaticConciliation
    ) {
      return false;
    }
    if (
      currentPayment.paymentMethod === "" ||
      (currentPayment.paymentMethod === "PIX" &&
        !currentPayment.chargedSeparately) ||
      (currentPayment.paymentMethod !== "PIX" &&
        !currentPayment.chargedSeparately)
    ) {
      return false;
    }

    return true;
  };

  if (!shouldRenderThisField()) {
    return null;
  }

  const handleChange = (aut) => setCurrentPayment({ aut });

  return <AutFieldInput value={currentPayment.aut} onChange={handleChange} />;
}
