import { useEffect, useState } from "react";
import { useIgluAnalyticsStore } from "../store/useIgluAnalyticsStore";

function calculateOrdersQuantity(fiscalData) {
  if (!fiscalData || fiscalData.length === 0) {
    return 0;
  }
  const numberOfOrders = fiscalData.length;
  return numberOfOrders;
}

function calculateTotalSalesValue(fiscalData) {
  if (!fiscalData || fiscalData.length === 0) {
    return 0;
  }
  const filteredData = fiscalData.filter(
    (invoice) => invoice.type !== "return",
  );

  if (filteredData.length === 0) {
    return 0;
  }

  const totalSalesValue = filteredData.reduce(
    (total, sale) => total + parseFloat(sale.total.finalValue) / 100,
    0,
  );
  return totalSalesValue;
}

function calculateAverageItemsPerOrder(fiscalData) {
  if (!fiscalData || fiscalData.length === 0) {
    return 0;
  }

  const totalItems = fiscalData.reduce(
    (total, sale) => total + sale.items.length,
    0,
  );
  const numberOfOrders = fiscalData.length;
  const averageItemsPerOrder =
    numberOfOrders === 0 ? 0 : totalItems / numberOfOrders;
  return averageItemsPerOrder;
}

function calculateAverageTicket(fiscalData) {
  if (!fiscalData || fiscalData.length === 0) {
    return 0;
  }

  const totalGrossSales = fiscalData.reduce(
    (total, sale) => total + parseFloat(sale.total.finalValue) / 100,
    0,
  );
  const numberOfOrders = fiscalData.length;
  const averageTicket =
    numberOfOrders === 0 ? 0 : totalGrossSales / numberOfOrders;
  return averageTicket;
}

function calculateTotalPaymentsMethod(fiscalData) {
  if (!fiscalData || fiscalData.length === 0) {
    return {
      credit: 0,
      debit: 0,
      pix: 0,
      cash: 0,
      link: 0,
      "gift-card": 0,
      "store-credit": 0,
    };
  }

  const methodsOptions = [
    "credit",
    "debit",
    "pix",
    "cash",
    "link",
    "gift-card",
    "store-credit",
  ];

  const totalPaymentsMethod = {};

  methodsOptions.forEach((method) => {
    totalPaymentsMethod[method] = 0;
  });

  const filteredSales = fiscalData.filter((sale) => {
    return sale && sale.type !== "return" && sale.payments;
  });

  filteredSales.forEach((sale) => {
    sale.payments
      .filter((payment) => methodsOptions.includes(payment.method))
      .forEach((payment) => {
        totalPaymentsMethod[payment.method] += payment.totalAmount / 100;
      });
  });

  return totalPaymentsMethod;
}

function calculateTotalDiscounts(fiscalData) {
  if (!fiscalData || fiscalData.length === 0) {
    return 0;
  }

  const filteredData = fiscalData.filter(
    (invoice) => invoice.type !== "return",
  );

  if (filteredData.length === 0) {
    return 0;
  }

  const totalDiscounts = filteredData.reduce(
    (total, sale) => total + parseFloat(sale.total.discountValue) / 100,
    0,
  );
  return totalDiscounts;
}

function calculateTotalReturn(fiscalData) {
  if (!fiscalData || fiscalData.length === 0) {
    return 0;
  }

  const filteredData = fiscalData.filter(
    (invoice) => invoice.type === "return",
  );

  if (filteredData.length === 0) {
    return 0;
  }

  const totalReturn = filteredData.reduce(
    (total, sale) => total + parseFloat(sale.total.finalValue) / 100,
    0,
  );
  return totalReturn;
}

function calculateGrossSales(fiscalData) {
  if (!fiscalData || fiscalData.length === 0) {
    return 0;
  }
  const totalGrossSales = fiscalData.reduce(
    (total, sale) => total + parseFloat(sale.total.finalValue) / 100,
    0,
  );
  return totalGrossSales;
}

function useFiscalData() {
  const [data, setData] = useState({
    numberOfOrders: 0,
    totalSalesValue: 0,
    averageItemsPerOrder: 0,
    averageTicket: 0,
    totalPaymentsMethod: {},
    totalGrossSales: 0,
    totalDiscounts: 0,
    totalReturn: 0,
    totalNetSales: 0,
  });

  const { fiscalInvoice } = useIgluAnalyticsStore();

  useEffect(() => {
    let filteredData = fiscalInvoice;

    const numberOfOrders = calculateOrdersQuantity(filteredData);
    const totalSalesValue = calculateTotalSalesValue(filteredData);
    const averageItemsPerOrder = calculateAverageItemsPerOrder(filteredData);
    const averageTicket = calculateAverageTicket(filteredData);
    const totalPaymentsMethod = calculateTotalPaymentsMethod(filteredData);
    const totalDiscounts = calculateTotalDiscounts(filteredData);
    const totalReturn = calculateTotalReturn(filteredData);
    const totalGrossSales = calculateGrossSales(filteredData);

    const updatedData = {
      numberOfOrders,
      totalSalesValue,
      averageItemsPerOrder,
      averageTicket,
      totalPaymentsMethod,
      totalDiscounts,
      totalReturn,
      totalGrossSales,
    };

    setData(updatedData);
  }, [fiscalInvoice]);

  return data;
}

export default useFiscalData;
