import { Button } from "@shopify/polaris";

export function LinkSubmit({ onClick, isForShipping }) {
  const buttonText = isForShipping ? "Cobrar" : "Gerar NF";

  return (
    <Button primary onClick={onClick}>
      {buttonText}
    </Button>
  );
}
