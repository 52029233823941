import { Page, Layout } from "@shopify/polaris";
import { useEffect } from "react";

import { Card } from "../../component/Card/Card.jsx";
import ErrorSituation from "../../component/errorSituation/ErrorSituation.jsx";
import { OrderCanceledFeedback } from "../../component/Feedback/OrderCanceledFeedback.jsx";
import withBackButton from "../../component/hoc/withBackButton.jsx";
import LoadingFrame from "../../component/LoadingFrame/LoadingFrame.jsx";
import { TabPanel } from "../../component/Tabs/TabPanel";
import getMaxInstallments from "../../helper/getMaxInstallments.js";
import useDevice from "../../hook/useDevice.js";
import useFirestoreDoc from "../../hook/useFirestoreDoc.js";
import useFirestoreQuery from "../../hook/useFirestoreQuery.js";
import useHideLoadingIndicator from "../../hook/useHideLoadingIndicator.js";
import useShopifyOrder from "../../hook/useShopifyOrder.js";
import useShopifyOrderId from "../../hook/useShopifyOrderId.js";
import useShopifyShopUrl from "../../hook/useShopifyShopUrl.js";
import { useConfigurationStore } from "../../store/useConfigurationStore.js";
import { useFiscalInvoiceStore } from "../../store/useFiscalInvoiceStore.js";
import { useLocationStore } from "../../store/useLocationStore.js";
import { useMultiplePaymentStore } from "../../store/useMultiplePaymentsStore.js";
import { useOrderStore } from "../../store/useOrderStore.js";
import {
  MULTIPLE_PAYMENT_STEPS,
  NFCE_STEPS,
  PAYMENT_STEPS,
  usePaymentFlowStore,
} from "../../store/usePaymentFlowStore.js";
import UnexpectedErrorPage from "../unexpectedErrorPage/UnexpectedErrorPage.jsx";
import { CardHeader } from "./components/CardHeader/CardHeader";
import { InformationPanel } from "./components/InformationPanel/InformationPanel.jsx";
import { NfcePanel } from "./components/NfcePanel/NfcePanel.jsx";
import { PaymentPanel } from "./components/PaymentPanel/PaymentPanel";
import { Container } from "./NfcePage.styles.js";

function NfcePage() {
  useHideLoadingIndicator();

  const {
    nfceStep,
    setNfceStep,
    setInstallmentOptions,
    setPaymentStep,
    setMultiplePaymentsStep,
  } = usePaymentFlowStore();

  const orderStore = useOrderStore();

  const { setInstallmentsConfig, installmentsConfig, setPayments } =
    useMultiplePaymentStore();

  const {
    setFiscalInvoiceId,
    setFiscalInvoiceStatus,
    fiscalInvoiceId,
    setFiscalInvoiceVincoError,
  } = useFiscalInvoiceStore();

  const device = useDevice();

  const shopifyOrderId = useShopifyOrderId();
  const companyShop = useShopifyShopUrl();

  const shopifyOrderRequest = useShopifyOrder({ id: shopifyOrderId });

  const configurationSnapshot = useFirestoreDoc("configuration", companyShop);

  const { configuration, setConfiguration } = useConfigurationStore();

  const fiscalInvoiceSnapShot = useFirestoreQuery(
    "fiscalInvoice",
    ["companyShop", "==", companyShop],
    ["shopifyOrderId", "==", shopifyOrderId],
    ["type", "==", "sale"],
  );

  const paymentQuerySnapshot = useFirestoreQuery(
    "payment",
    ["companyShop", "==", companyShop],
    ["shopifyOrderId", "==", shopifyOrderId],
    ["status", "==", "settled"],
  );

  const shopifyOrderDoc = useFirestoreDoc(
    "shopifyOrder",
    `${companyShop}:${shopifyOrderId}`,
  );

  const locationId = device?.pos?.location?.id;
  const { location, setLocation } = useLocationStore();

  const locationSnapshot = useFirestoreDoc(
    "posLocation",
    `${companyShop}:${locationId}`,
  );

  useEffect(() => {
    if (configurationSnapshot?.data?.installments) {
      setInstallmentsConfig(configurationSnapshot.data.installments);
    }

    if (configurationSnapshot.didSucceed) {
      setConfiguration(configurationSnapshot.data);
    }
  }, [configurationSnapshot.didSucceed, configurationSnapshot.data]);

  useEffect(() => {
    if (installmentsConfig && shopifyOrderRequest?.data?.orderTotalInCents) {
      setInstallmentOptions(
        getMaxInstallments({
          installments: installmentsConfig,
          totalAmount: shopifyOrderRequest?.data?.orderTotalInCents / 100,
        }),
      );
    }
  }, [installmentsConfig, shopifyOrderRequest?.data?.orderTotalInCents]);

  useEffect(() => {
    if (shopifyOrderRequest?.data) {
      const currentLocationId = `${device?.pos?.location?.id}`;
      const posDeviceId = `${device?.pos?.device?.serialNumber}`;
      const staffId = device?.pos?.user?.id ? `${device.pos.user.id}` : null;

      if (shopifyOrderRequest?.data?.cpf) {
        orderStore.setCpf(shopifyOrderRequest?.data?.cpf);
      }

      if (shopifyOrderRequest?.data?.name) {
        orderStore.setShopifyOrderName(shopifyOrderRequest?.data?.name);
      }
      if (staffId) {
        orderStore.setStaffId(staffId);
      }

      if (shopifyOrderRequest?.data?.id) {
        orderStore.setShopifyOrderId(shopifyOrderRequest?.data?.id);
      }

      if (companyShop) {
        orderStore.setCompanyShop(companyShop);
      }

      if (currentLocationId) {
        orderStore.setPosLocationId(currentLocationId);
      }

      if (posDeviceId) {
        orderStore.setPosDeviceId(posDeviceId);
      }

      if (shopifyOrderRequest?.data?.customer?.email) {
        orderStore.setEmail(shopifyOrderRequest?.data?.customer?.email);
      }

      if (shopifyOrderRequest?.data?.shippingLine) {
        orderStore.setShippingLine(shopifyOrderRequest?.data?.shippingLine);
      }

      if (
        shopifyOrderRequest?.data?.channelInformation?.channelDefinition
          ?.channelName
      ) {
        orderStore.setChannelName(
          shopifyOrderRequest?.data?.channelInformation?.channelDefinition
            ?.channelName,
        );
      }

      if (shopifyOrderRequest?.data?.isForShipping) {
        orderStore.setIsForShipping(shopifyOrderRequest?.data?.isForShipping);
      }

      if (shopifyOrderRequest?.data?.orderTotalInCents) {
        orderStore.setOrderTotalInCents(
          shopifyOrderRequest?.data?.orderTotalInCents,
        );
      }
    }
  }, [shopifyOrderRequest?.data]);

  useEffect(() => {
    if (shopifyOrderRequest?.data && fiscalInvoiceSnapShot?.data?.length > 0) {
      const fiscalInvoice = fiscalInvoiceSnapShot.data.find(
        (invoice) => invoice.type === "sale",
      );
      if (fiscalInvoice) {
        setFiscalInvoiceId(fiscalInvoice.id);
        setFiscalInvoiceStatus(fiscalInvoice.status);
        setFiscalInvoiceVincoError(fiscalInvoice.vincoError);
      }
    }
  }, [fiscalInvoiceSnapShot.data, shopifyOrderRequest.data]);

  useEffect(() => {
    if (shopifyOrderRequest?.data && fiscalInvoiceId) {
      setNfceStep(NFCE_STEPS.NFCE.value);
      return;
    }

    if (!paymentQuerySnapshot.data) {
      return;
    }

    if (paymentQuerySnapshot.data.length === 0) {
      setPayments([]);
      return;
    }

    const newPayments = paymentQuerySnapshot.data
      .sort((a, b) => (a?.createdAt > b?.createdAt ? 1 : -1))
      .map((payment) => ({
        ...payment,
        status: "PAID",
        value: parseInt(payment.amountCents, 10),
        paymentMethod: payment.method.toUpperCase(),
        paymentInstallments: payment.installments,
        chargedSeparately: payment.wasConciliation,
        nsu: Array.isArray(payment.nsu) ? payment.nsu.join(",") : payment.nsu,
        linkPaymentService: null,
        pixService: null,
        automaticConciliation: null,
      }));

    const totalPaymentsAmount = newPayments.reduce((total, payment) => {
      return total + payment.value;
    }, 0);

    if (
      newPayments.length === 1 &&
      totalPaymentsAmount === orderStore.orderTotalInCents
    ) {
      setPayments(newPayments);
      // setPayment(newPayments[0]);
      return;
    }

    if (orderStore.orderTotalInCents) {
      setPayments(newPayments);

      setNfceStep(NFCE_STEPS.PAYMENT.value);
      setPaymentStep(PAYMENT_STEPS.MULTIPLE_PAYMENTS.value);
      setMultiplePaymentsStep(MULTIPLE_PAYMENT_STEPS.PAYMENT_FORM_LIST.value);
    }
  }, [
    paymentQuerySnapshot?.data,
    orderStore.orderTotalInCents,
    fiscalInvoiceId,
    shopifyOrderRequest?.data,
  ]);

  useEffect(() => {
    if (locationSnapshot.didSucceed) setLocation(locationSnapshot.data);
  }, [locationSnapshot.didSucceed, locationSnapshot.data]);

  if (
    configurationSnapshot.isLoading ||
    paymentQuerySnapshot.isLoading ||
    shopifyOrderRequest.isLoading ||
    fiscalInvoiceSnapShot.isLoading ||
    locationSnapshot.isLoading ||
    !installmentsConfig ||
    !configuration ||
    !location ||
    shopifyOrderRequest?.data?.orderTotalInCents === null
  ) {
    return (
      <Page>
        <LoadingFrame />
      </Page>
    );
  }

  const shopifyOrder = shopifyOrderDoc.data;

  const ecommerceOrder = shopifyOrder?.errorSituation?.isEcommerce;
  const orderAlreadyCanceled = shopifyOrder?.errorSituation?.isSaleNfCancelled;
  const orderAlreadyReturned = shopifyOrder?.errorSituation?.isNfeReturned;

  const possibilityOfErrors = {
    ecommerceOrder,
    orderAlreadyCanceled,
    orderAlreadyReturned,
  };

  if (shopifyOrderRequest.didFail) {
    return <UnexpectedErrorPage error={shopifyOrderRequest.error} />;
  }

  if (shopifyOrder?.wasCanceled) {
    return <OrderCanceledFeedback />;
  }

  return (
    <Page>
      <Layout>
        <Layout.Section>
          <Card>
            <ErrorSituation
              conditions={possibilityOfErrors}
              path={"/one-button-page"}
              cases={{
                ecommerceOrder: {
                  title: "Não é possível cancelar a nota fiscal",
                  errorMessage:
                    "Desculpe, não é possível cancelar a nota fiscal deste pedido, pois ele foi feito pelo e-commerce. Se precisar de assistência, entre em contato com o responsável financeiro da sua empresa.",
                },
                orderAlreadyCanceled: {
                  title: "Este pedido já teve a nota fiscal cancelada",
                  errorMessage:
                    "A nota fiscal de venda já foi cancelada para este pedido. Não há nada para cancelar no momento. Se precisar de assistência, entre em contato com o responsável financeiro da sua empresa.",
                },
                orderAlreadyReturned: {
                  title: "Este Pedido já foi devolvido",
                  errorMessage: "Este pedido já foi devolvido anteriormente.",
                },
              }}
            >
              <CardHeader />
              <Container>
                <TabPanel value={nfceStep} index={NFCE_STEPS.INFORMATION.value}>
                  <InformationPanel />
                </TabPanel>
                <TabPanel value={nfceStep} index={NFCE_STEPS.PAYMENT.value}>
                  <PaymentPanel />
                </TabPanel>
                <TabPanel value={nfceStep} index={NFCE_STEPS.NFCE.value}>
                  <NfcePanel
                    configuration={configuration}
                    location={location}
                    paymentList={paymentQuerySnapshot.data}
                  />
                </TabPanel>
              </Container>
            </ErrorSituation>
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  );
}

export default withBackButton(NfcePage, "/one-button-page");
